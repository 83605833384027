import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Raschin
Jigsaw: A Photoshop Learning Game
This project is a collaboration with: Mira Doncheva (Adobe ATL) and Tao Dong`}</p>
    <p>{`Learning how to use complex software, such as Photoshop, can be a challenging task. Even experts spend a considerable amount of time improving their Photoshop skills in order to streamline workflows and keep up with new features.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2012/02/photoshop1.jpg",
        "alt": "photoshop"
      }}></img></p>
    <p>{`How can we make the learning experience of Photoshop engaging, enjoyable, and serendipitous? `}</p>
    <p>{`In this project, we explore the potential of computer games as an answer to this question. Specifically, we developed Jigsaw, an extension for Photoshop that allows users to learn Photoshop while solving virtual jigsaw puzzles. We have developed a set of puzzles that cover key features of Photoshop including layers, selection, transformation, adjustment layers, and  layer masks.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/prototype.png",
        "alt": "prototype"
      }}></img></p>
    <p>{`The extension incorporates many game mechanisms such as performance feedback, hints, and a leader board. Our preliminary observations of user interactions with the game reveal  the great educational potential of playing the game as well as the challenge of making it fun and engaging.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/jigsawwireframe.jpg",
        "alt": "prototype_2"
      }}></img></p>
    <p>{`Playing Jigsaw inside Photoshop:`}</p>
    <p>{` `}<a parentName="p" {...{
        "href": "https://video.tv.adobe.com/v/13256/?"
      }}>{`Watch Video`}</a></p>
    <p>{`2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      